import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDModal from 'components/MDModal'
import MDTypography from 'components/MDTypography'
import { useAppServices, useUploadImage } from 'hook/services'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useAgencyInfo } from "context/agency";
import Multiselect from './components/Multiselect'
import { Switch, TextareaAutosize, Tooltip } from '@mui/material'
import infoimg from "../info.png"
import ReactSelect from 'react-select'
import ModelPromptslabels from './components/createtag'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 550,
  bgcolor: 'background.paper',
  border: '2px  #000',
  boxShadow: 24,
  p: 4,
  overflow: "hidden",
}
const DocsValidate = yup.object({
  name: yup.string().required().label('Name'),
})
function Create({ open, onClose }) {
  const [agency, Update] = useAgencyInfo();
  const uploadImage = useUploadImage()
  const [processing, setProcessing] = useState(false)
  const AppService = useAppServices()

  const [contentCreativity, setContentCreativity] = useState(1);
  const [btgen, setBtgen] = useState(1);
  const [modalName, setModalName] = useState([]);
  const [tags, setTags] = useState([]);
  const [modaloption, setmodaloption] = useState([]);
  const [tagsoption, settagsoption] = useState([]);
  const [addTagModel, setAddTagModel] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [promptQuery, setPromptQuery] = useState("");
  const [promptstep, setpromptstep] = useState(0);
  const [promptQueryError, setPromptQueryError] = useState("");
  const [uploadedFileData, setUploadedFileData] = useState([]);
  const [alertModel, setAlertModel] = useState(false);
  const [showStep, setshowStep] = useState(false);
  const [apiType, setapiType] = useState("");




  const [formData, setformData] = useState({
    promptName: "",
    description: '',
    tools: [],
    temperature: '',
    top_p: '',
    model: '',
    tagsa: [],
    agency_id: '',
    profile_id: "",
    template_id: ""
  })

  const GeneratePrompt = async () => {
    setpromptstep(1);
    try {
      const payload = {
        username: "Agency Prompt Generator",
        promptQuery: promptQuery,
        type: "assistant",
        file_ids: uploadedFileData,
      };
      const { response } = await AppService.prompts.promptGeneratorBySupperAdmin({ payload });
      if (response) {
        setpromptstep(2);
        const updatedFormData = {
          ...formData,
          promptName: response?.data?.title || "",
          description: response?.data?.content || "",
        };
        setformData(updatedFormData)

        var temperature = Number(response?.data?.tempratore);
        var top_p = Number(response?.data?.top_p);
        setContentCreativity(temperature);
        setBtgen(top_p);
        console.log(formData, response.data, "responseresponseresponse")

      }
    } catch (error) {
      return error;
    }
  };
  const handlestep1 = () => {
    if (promptQuery === "") {
      setPromptQueryError(true);
      return false;
    }
    setPromptQueryError(false);
    GeneratePrompt();
  };
  const detectEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handlestep1();
    }
  };
  const UploadFile = async (files) => {
    try {
      const user = await localforage.getItem("user").then((userObject) => {
        if (userObject) {
          return userObject;
        } else {
          console.log("No user found in localForage");
        }
      });
      console.log(user);
      const formData = new FormData();
      for (let file of files) {
        formData.append("file", file.file);
      }

      const assistantIds = [agency?.assistant_id];
      formData.append("profile_id", agency?.ghl ? agency.ghl.location_id : agency.location_id);
      formData.append("username", "AgencyUser");
      formData.append("model_name", "openai/gpt-4o");
      // formData.append("assistant_ids", JSON.stringify(assistantIds));

      const { response } = await AppService.chat.saveMultimedia({
        payload: formData,
        toaster: true,
      });

      console.log(response, "files for response");

      setUploadedFileData(response.data);
      if (response) {
        setAlertModel(false);
        onLoad();
        setSuccessModel(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFile = (indexToRemove) => {
    const updatedData = uploadedFileData.filter((_, index) => index !== indexToRemove);
    setUploadedFileData(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!e.target.assistant_type.value) {
      alert("Please select an Agent type");
      return true;
    }
    let requestrunner;
    if (
      e.target.assistant_type.value === "agency" ||
      e.target.assistant_type.value === "automation"
    ) {
      setProcessing(true);
      const payload = {
        name: formData.promptName,
        instructions: formData.description,
        model: "openai/gpt-4o",
        temperature: contentCreativity,
        top_p: btgen,
        agency_id: "superYxadminagency_id",
        profile_id: 'superYxadminprofile_id',
        readonly: true,
        type: "super-admin",
        status: false,
        assist_type: e.target.assistant_type.value === "agency" ? "agency" : "automation",
        for_support: e.target.assistant_type.value === "automation" ? true : false,
        advanceConversationMode: advanceConversationMode,
        numberOfMessages: numberOfMessages,
        messageDelayTime: messageDelayTime,
        function_names: activePlugins,
      };

      requestrunner = await AppService.assistant.create({ toaster: true, payload });
      setShowAdvancedSettings(false);
      setAdvanceConversationMode(false);
      setNumberOfMessages(0);
      setMessageDelayTime(0);
      setActivePlugins("");
      setProcessing(false);

    } else {
      if (e.target.templatename && e.target.templatename?.value === "") {
        setProcessing(false);
        alert("Please Enter Template name");
        return true;
      }
      setProcessing(true);

      const payload = {
        name: formData.promptName,
        instructions: formData.description,
        agency_id: "superYxadminagency_id",
        profile_id: 'superYxadminprofile_id',
        temperature: contentCreativity,
        model: "openai/gpt-4o",
        top_p: btgen,
        readonly: true,
        type: "super-admin",
        status: false,
        api_type: apiType,
        templatename: e.target.templatename.value,
      };
      requestrunner = await AppService.template.create({ toaster: true, payload });
    }



    if (requestrunner) {
      setProcessing(false);
      setpromptstep(0)
      setPromptQuery("")
      onClose()
      // runAPIofStoreAsisstant(payload);
    }
    // Submit logic here, e.g., calling API with AppService
  };
  const handleChangeTags = (selectedOptions) => {
    setTags(selectedOptions ? selectedOptions?.map(option => option.value) : []);
    setformData({
      ...formData,
      tagsa: selectedOptions ? selectedOptions?.map(option => option.label) : []
    });
  };

  const handleCCInputChange = (event) => {
    const tempSliderValue = event.target.value;
    const progress = (tempSliderValue / event.target.max) * 100;

    event.target.style.background = `linear-gradient(to right, gray ${progress}%, #ccc ${progress}%)`;

    setContentCreativity(tempSliderValue); // Set actual value, not progress percentage
  };

  const handleBtInputChange = (event) => {
    const tempSliderValue = event.target.value;
    const progress = (tempSliderValue / event.target.max) * 100;
    event.target.style.background = `linear-gradient(to right, gray ${progress}%, #ccc ${progress}%)`;

    setBtgen(tempSliderValue); // Set actual value, not progress percentage
  };
  const GetModels = async () => {
    const { response } = await AppService.assistant.Get();
    if (response) {
      setmodaloption(response.data)
    }
  }
  const promptslabel = async () => {
    const { response } = await AppService.promptslabel.Get({
      query: `type=super-admin`,
    });
    if (response) {
      console.log("Superadmin tag options", tagsoption)
      settagsoption(response.data)

    }
  }
  useEffect(() => {
    GetModels(); promptslabel(); loadPluginData();
  }, [])



  const savedataPromptLabel = async (formData) => {
    const updatedFormData = {
      ...formData,
      agency_id: agency._id,
      type: "super-admin"
    };
    const payload = updatedFormData;

    const { response } = await AppService.promptslabel.create({
      payload,
      toaster: true,
    });
    if (response) {
      handleclose();
      setAddTagModel(false);
      promptslabel();
    }

  }
  const handleclose = () => {
    setAddTagModel(false);
  }
  const handleAddTag = () => {
    setAddTagModel(true);
  }
  const selectassistanttype = (e) => {
    if (e.target.value === "agency" || e.target.value === "automation") {
      setshowStep(false);
      return true;
    }
    if (e.target.value === "account-specific") {
      setapiType("team");
      setshowStep(true);
      return true;
    }
    if (e.target.value === "user-specific") {
      setapiType("user");
      setshowStep(true);
      return true;
    }
  };
  const formatString = (str) => {
    // Replace underscores with spaces
    str = str.replace(/_/g, ' ');

    // Add space before each capital letter, ensuring no double spaces
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Trim and return the final result
    return str.trim();
  };
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [advanceConversationMode, setAdvanceConversationMode] = useState(false); // State for the toggle button
  const [numberOfMessages, setNumberOfMessages] = useState(0); // State for the first number input field
  const [messageDelayTime, setMessageDelayTime] = useState(0);
  // console.log("toggle Value", messageDelayTime)
  // Toggle Advanced Settings
  const handleAdvancedSettingsClick = () => {
    setShowAdvancedSettings(!showAdvancedSettings);
  };
  // Array to store active plugin names
  const [activePlugins, setActivePlugins] = useState([]);
  const [pluginsdata, setPluginsData] = useState([]);

  // console.log("plugins", activePlugins)
  // Handle toggle change for both plugins
  const handlePluginsChange = (event) => {
    const pluginName = pluginsdata.find((plugin) => plugin.name === event.target.name).name;

    // Toggle the ID in the active plugins array
    setActivePlugins((prevState) => {
      if (prevState.includes(pluginName)) {
        // If the ID is already in the active array, remove it
        return prevState.filter((name) => name !== pluginName);
      } else {
        // Otherwise, add it to the active array
        return [...prevState, pluginName];
      }
    });
  };
  const loadPluginData = async () => {
    const { response } = await AppService.assistant.Getplugin();
    if (response) {
      console.log(response.data, "pluginsdata")
      setPluginsData(response.data);
    }
  };
  return (
    <MDBox>
      <MDModal
        open={open}
        onClose={onClose}
        height={550}
        width={500}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "transparent",
        }}
      >
        {promptstep == 0 && (
          <>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%", // Full viewport height
                width: "100%", // Full viewport width
              }}
            >
              <div className="input-container" style={{ width: "100%", maxWidth: "600px" }}>
                <textarea
                  value={promptQuery}
                  required
                  placeholder={"Start typing..."}
                  name="promptQuery"
                  onChange={(e) => setPromptQuery(e.target.value)}
                  onKeyDown={(e) => detectEnterPress(e)}
                  className="input_od_addPrompt"
                  style={{
                    width: "100%",
                    padding: "20px",
                    borderRadius: "8px",
                    maxHeight: "200px",
                    resize: "none",
                  }}
                  rows={1}
                  onInput={(e) => {
                    e.target.style.height = "auto"; // Reset height
                    e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
                  }}
                />
                <div style={{ textAlign: "right", marginTop: "10px" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    type="submit"
                    className="brand-card-background"
                    loading={processing}
                    disabled={processing}
                    sx={{ mt: 1, mb: 1 }}
                    onClick={handlestep1}
                  >
                    Send
                  </MDButton>

                </div>
              </div>
            </div>

            {promptQueryError && <small style={{ color: "red" }}>Should not be empty</small>}
          </>
        )}
        {promptstep == 1 && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%", // Full viewport height
                width: "100%", // Full viewport width
              }}
            >
              <p
                className="roundofaddprompt z-w-100 text-center"
                style={{
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >


                <span style={{ display: "inline-block", marginLeft: "10px" }}>
                  Creating Agent ...
                </span>

              </p>
            </div>
          </>
        )}

        {promptstep !== 0 && promptstep !== 1 && <MDBox role="form" component="form" onSubmit={handleSubmit} sx={style}>
          {promptstep == 2 &&
            <>
              <MDTypography variant="h6">Create Assistant</MDTypography>
              <MDBox my={1}>
                <label className="text-black" style={{ display: "flex", alignItems: "center" }} >
                  <span style={{ fontSize: "14px" }}>Assistant Name</span> </label>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <input type="text" style={{ width: "100%" }} name='promptName' defaultValue={formData?.promptName} />

                </div>
              </MDBox>

              <MDBox my={1}>
                <label style={{ fontSize: "14px" }}>Porpose</label>
                <TextareaAutosize
                  style={{ width: "100%", height: "10vh" }}
                  type="text"
                  label="description"
                  name="description"
                  defaultValue={formData?.description}
                  fullWidth
                />
              </MDBox>

              <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
                <MDBox my={1}>
                  <label className="text-black" style={{ display: "flex", alignItems: "center" }}><span style={{ fontSize: "14px" }}>Content Creativity</span> </label>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="range" defaultValue={formData?.content_creativity ? formData?.content_creativity : contentCreativity} min="0" max="2" step={"0.01"} onChange={handleCCInputChange} className="range-input" />

                    <input
                      type="number"
                      value={formData?.content_creativity ? formData?.content_creativity : contentCreativity}
                      min="0"
                      max="2"
                      step="0.01"
                      onChange={(event) => handleCCInputChange(event)}
                      style={{
                        maxWidth: "50px",
                        fontSize: "14px",
                        margin: "2px",
                        padding: "0px 2px",
                        borderRadius: "5px",
                        border: "1px solid Black"
                      }}
                      className="height_25 bg_transparent removeControl"
                    />

                  </div>
                </MDBox>

                <MDBox my={1}>
                  <label className="text-black" style={{ display: "flex", alignItems: "center" }} ><span style={{ fontSize: "14px" }}>Balance Text Generation</span> </label>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="range" min="0" max="1" defaultValue={formData?.btgen ? formData?.btgen : btgen} step={"0.01"} onChange={handleBtInputChange} className="range-input" />

                    <input
                      type="number"
                      value={formData?.btgen ? formData?.btgen : btgen}
                      min="0"
                      max="1"
                      step="0.01"
                      onChange={(event) => handleBtInputChange(event)}
                      style={{
                        maxWidth: "50px",
                        fontSize: "14px",
                        margin: "2px",
                        padding: "0px 2px",
                        borderRadius: "5px",
                        border: "1px solid Black"
                      }}
                      className="height_25 bg_transparent"
                    />

                  </div>
                </MDBox>
              </MDBox>

              <MDBox my={1}>
                <MDTypography className="text-black w-100 d-flex justify-content-between" variant="p" sx={{ fontSize: "14px", fontWeight: "500", alignItems: 'center' }} mb={1}>
                  <label>
                    Tags*
                  </label>
                  <p style={{ cursor: 'pointer', textAlign: 'right', margin: 0 }} onClick={handleAddTag}>
                    + Add Tag
                  </p>
                </MDTypography>
                <ReactSelect
                  name='tags'
                  className="form-input w-full"
                  options={Array.isArray(tagsoption) ? tagsoption.map((item) => ({
                    label: item.name,
                    value: item._id
                  })) : []}

                  isMulti={true}
                  onChange={handleChangeTags}
                />
              </MDBox>
            </>
          }


          {promptstep == 3 && <>

            <MDBox item xs={12} mt={2}>
              <label className="text-black" style={{ display: "flex", alignItems: "center" }}>
                <span>Select type</span>
              </label>

              <MDBox className="mt-2" style={{ display: "flex", alignItems: "center" }}>
                <select
                  name="assistant_type"
                  onChange={selectassistanttype}
                  className="w-100 selectstyle"
                >
                  <option value={""}>----Select the type------</option>
                  <option value={"agency"}>All Accounts</option>
                  <option value={"account-specific"}>Account Specific</option>
                  <option value={"user-specific"}>User Specific</option>
                  <option value={"automation"}>Automation</option>
                </select>
              </MDBox>
            </MDBox>

            {showStep && (
              <MDBox item xs={12} mt={2}>
                <label className="text-black" htmlFor="for_support">
                  Template name
                </label>
                <MDBox className="mt-2" style={{ display: "flex", alignItems: "center" }}>
                  <input
                    name="templatename"
                    id="templatename"
                    className="form-control-agust"
                  />
                </MDBox>
              </MDBox>
            )}

            {/* Advanced Settings Button */}
            <MDBox style={{ width: "100%", textAlign: "right" }} mt={2}>
              <MDButton onClick={handleAdvancedSettingsClick} style={{ color: "#000" }}>
                Advanced Settings
              </MDButton>
            </MDBox>
            {showAdvancedSettings && (
              <div style={{ marginTop: "0px", padding: "10px" }}>
                <h2
                  className="text-black text-left font-14"
                  style={{ fontWeight: "700", marginBottom: "20px", marginLeft: "-15px" }}
                >
                  {" "}
                  Advance Setting{" "}
                </h2>
                <MDBox container spacing={2}>
                  {/* <Grid item xs={12} style={{ marginBottom: '20px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                              <label className="text-black" style={{ marginRight: '0px' }}>Switch on advanced conversation mode</label>
                              <Tooltip PopperProps={{
                                sx: {
                                  "& .MuiTooltip-tooltip": {
                                    maxWidth: "none",
                                    width: "400px",
                                    marginTop: "30px"
                                  },
                                },
                              }} placement="top" title={supperAdminData.pluginInfo}>
                                <img style={{ width: "30px", cursor: "pointer" }} src={infoimg} />
                              </Tooltip>

                            </div>
                            <div>
                              <Switch
                                checked={advanceConversationMode}
                                onChange={handleToggleChange}
                                name="enableFeature"
                                inputProps={{ 'aria-label': 'Enable Feature' }}
                              />
                            </div>

                          </div>
                        </Grid>

                        <Grid item xs={12} style={{ marginBottom: '15px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <label className="text-black">Number of messages</label>
                            <TextField
                              className="numberMessages"
                              type="number"
                              value={numberOfMessages}
                              onChange={(e) => handleNumberInputChange(e, setNumberOfMessages)}
                              fullWidth
                              inputProps={{
                                min: 2,
                                max: 10, // Set max to 10
                                step: 1 // Optional: Only allow whole numbers
                              }}
                              style={{
                                width: '30%',
                                padding: '0 8px',
                                height: '16px !important',
                                fontSize: '14px',
                                lineHeight: '16px',
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12} style={{ marginBottom: '20px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <label className="text-black">Delay between messages Seconds:</label>
                            <TextField
                              type="number"
                              value={messageDelayTime}
                              onChange={(e) => handleNumberInputChange(e, setMessageDelayTime)}
                              fullWidth
                              inputProps={{ min: 0 }}
                              style={{
                                width: '30%',
                                padding: '0 8px', // Adjust the padding to match the reduced height
                              }}
                            />
                          </div>
                        </Grid> */}

                  {/* {agency?.default_plugins && (
                    <> */}
                  <h2
                    className="text-black text-left font-14"
                    style={{ fontWeight: "700", marginBottom: "20px" }}
                  >
                    {" "}
                    Plugins{" "}
                  </h2>
                  {pluginsdata?.map((plugin) => (
                    <MDBox item xs={12} style={{ marginBottom: "20px" }} key={plugin.id}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="text-black" style={{ marginRight: "10px" }}>
                          {formatString(plugin.name).toUpperCase()}
                        </label>
                        <Switch
                          checked={activePlugins.includes(plugin.name)}
                          onChange={handlePluginsChange}
                          name={plugin.name}
                          inputProps={{
                            "aria-label": `Enable ${formatString(plugin.name)}`,
                          }}
                        />
                      </div>
                    </MDBox>
                  ))}
                  {/* </>
                  )} */}
                </MDBox>
              </div>
            )}
          </>}
          {promptstep == 3 ?
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
              className="brand-card-background"
              loading={processing}
              disabled={processing}
              sx={{ mt: 1, mb: 1 }}
              fullWidth
            >
              {formData?.name ? 'Update' : 'Save'}
            </MDButton> : <div
              style={{
                background: "blue",
                color: "white",
                border: "none",
                width: "100%",
                padding: "10px 20px",
                textAlign: "center",
                cursor: "pointer"
              }}
              onClick={() => setpromptstep(3)}
            >
              Next
            </div>}
        </MDBox>}
      </MDModal>
      {
        addTagModel && (
          <ModelPromptslabels data={null} modelfunctionality={savedataPromptLabel} openModel={addTagModel} onclosemodel={handleclose} />
        )
      }
      {alertModel && (
        <div className="modal-zz" onClick={() => setAlertModel(false)}>
          <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
            <p
              style={{
                position: "absolute",
                right: 5,
                width: "12px",
                top: "10px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={() => setAlertModel(false)}
            ></p>

            <MyDropzone UploadFile={UploadFile} />
          </div>
        </div>
      )}
    </MDBox>
  )
}

export default Create
