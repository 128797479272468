// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Data
import { useState } from 'react';
import { Link } from 'react-router-dom';
import MDButton from 'components/MDButton';
import DataTable from 'examples/Tables/DataTable';
import authorsTableData from '../data/authorsTableData';
import CreateAssistant from './Create.js';

function Options() {
  const { columns, rows, templateCol, templates, getOptions, prices } = authorsTableData();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0); // State for tab selection

  const handleClose = () => {
    getOptions();
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Assistant
                </MDTypography>

                <MDButton
                  onClick={() => setOpen(true)}
                  sx={{ marginLeft: 'auto' }}
                  variant="outlined"
                >
                  Create Assistant
                </MDButton>
                <CreateAssistant open={open} onClose={handleClose} />
              </MDBox>

              {/* Tabs for different views */}
              <MDBox pt={3}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Assistants" />
                  <Tab label="Templates" />
                  <Tab label="KB" />
                </Tabs>
              </MDBox>

              <DndProvider backend={HTML5Backend}>
                <MDBox pt={3}>
                  {value === 0 && (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                  {value === 1 && (
                    <DataTable
                      table={{ columns: templateCol, rows: templates }} // Use filtered rows for agency only
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </DndProvider>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Options;
