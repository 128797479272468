// Material Dashboard 2 React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { useAppServices } from 'hook/services'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MDButton from 'components/MDButton'
import Swal from 'sweetalert2'
import { Icon } from '@mui/material'
import EditModel from '../Options/EditModel'
import Files from '../Options/Files'

export default function data() {
  const AppService = useAppServices()
  const [loader, setLoader] = useState(true)
  const [options, setoptions] = useState([])
  const [templates, settemplates] = useState([])
  const [prices, setprices] = useState([])
  const [open, setOpen] = useState(false)
  const [openFile, setopenFile] = useState(false)
  const navigate = useNavigate()
  const [subcategories, setSubcategories] = useState({})
  const [prompts, setPrompts] = useState({})

  const [selected, setSelected] = useState()
  const handleOnClick = (item, idx) => {
    setOpen(idx)
    setSelected(item)
  }
  const handleDelete = async (item, idx) => {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
    }).then(async (res) => {
      if (!res.isConfirmed) return ''
      const { response } = await AppService.assistant.Delete({
        query: `_id=${item._id}&&assistant_id=${item.assistant_id}`,
        toaster: true,
      })
      if (response) {
        options.splice(idx, 1)
        setoptions([...options])
      }
    })
  }
  const handleDeleteTemplate = async (item, idx) => {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete?',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
    }).then(async (res) => {
      if (!res.isConfirmed) return ''
      const { response } = await AppService.template.Delete({
        query: `_id=${item._id}&&agency_id=${item.agency_id}&&template_id=${item?.template?.id}`,
        toaster: true,
      })
      if (response) {
        templates.splice(idx, 1)
        settemplates([...templates])
      }
    })
  }
  const getOptions = async () => {
    const { response } = await AppService.assistant.Getassistantforsupperadmin()
    if (response) {
      const sortedData = response.data.sort((a, b) => {
        return b._id - a._id;
      });
      setoptions(sortedData)
      settemplates(response.templates)
    }
    setLoader(false)
  }

  const onLoad = () => {
    getOptions()
  }
  useEffect(onLoad, [])

  const handleClose = () => {
    setOpen(false);
    setopenFile(false)
    getOptions();
  }
  const showfiles = (item, idx) => {
    setopenFile(idx)
    setSelected(item)

  }

  return {

    columns: [
      { Header: 'assistant_name', accessor: 'assistant_name', align: 'left', width: '10%', verticalAlign: 'baseline' },
      {
        Header: 'purpose',
        accessor: 'purpose',
        align: 'left',
        width: '10%',
        verticalAlign: 'baseline',
      },

      {
        Header: 'action',
        accessor: 'action',
        align: 'center',
        width: '10%',
        verticalAlign: 'baseline',
      },
    ],

    rows: options.map((item, idx) => ({

      assistant_name: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item.assistant_name}
          </MDTypography>
        </div>
      ),

      purpose: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item.purpose}
          </MDTypography>
        </div>
      ),
      action: (
        <>
          {/* <MDButton
            onClick={() => showfiles(item, idx)}
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
          >
            Files{' '}
          </MDButton> */}
          <MDButton
            onClick={() => handleOnClick(item, idx)}
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
          >
            Edit{' '}
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
            onClick={() => handleDelete(item, idx)}
          >
            delete
          </MDButton>
          {selected == item &&
            <>
              <EditModel options={selected} open={open === idx} onClose={handleClose} />
              {/*<Files options={selected} open={openFile === idx} onClose={handleClose} /> */}
            </>
          }

        </>
      ),
    })),
    templateCol: [
      { Header: 'name', accessor: 'name', align: 'left', width: '10%', verticalAlign: 'baseline' },
      {
        Header: 'instruction',
        accessor: 'instruction',
        align: 'left',
        width: '10%',
        verticalAlign: 'baseline',
      },

      {
        Header: 'action',
        accessor: 'action',
        align: 'center',
        width: '10%',
        verticalAlign: 'baseline',
      },
    ],
    templates: templates?.filter((item => item?.template)).map((item, idx) => ({
      name: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item?.template?.name}
          </MDTypography>
        </div>
      ),

      instruction: (
        <div>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item?.template?.instructions}
          </MDTypography>
        </div>
      ),
      action: (
        <>
          {/* <MDButton
            onClick={() => showfiles(item, idx)}
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
          >
            Files{' '}
          </MDButton>*/}
          {/* <MDButton
            onClick={() => handleOnClick(item, idx)}
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
          >
            Edit{' '}
          </MDButton> */}
          <MDButton
            variant="gradient"
            color="info"
            style={{ marginRight: '10px' }}
            onClick={() => handleDeleteTemplate(item, idx)}
          >
            delete
          </MDButton>
          {selected == item &&
            <>
              {/* <EditModel options={selected} open={open === idx} onClose={handleClose} /> */}
              {/*<Files options={selected} open={openFile === idx} onClose={handleClose} /> */}
            </>
          }

        </>
      ),
    })),
    getOptions,
  }
}
